export const authors = [
    "François Tshipuki",
    "Cédrick Aundu",
    "Fady Koyabambi",
    "Manassé Mbala",
    "Masand Mafuta",
    "Keren Makope",
    "Marcus Kasembe",
    "Grey Lulendo",
    "Chris K.",
    "Silas M.",
    "Horace Codo T.",
    "Olivia Lupembe",
    "Joël Losinu",
    "Emery Kabongo",
    "Becky Kilo",
    "Moise Kashala"
]